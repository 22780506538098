import * as React from "react"
import PageTemplate from "../componets/pageTemplate";
import {AspectImage, Card, Container, Text, Flex, Box, Divider} from "@theme-ui/components";
import {Heading, Image, Link, Embed} from "theme-ui";

const mapsLink = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.4198939666153!2d13.757272815965692!3d51.06379845086051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4709cf39c875e919%3A0xf083bdfcd23e51d3!2sBlume%2C%20dbs%20dresden%20consult%20Thomas%20Dr.%20Finanzdienstleistungen!5e0!3m2!1sde!2sde!4v1615917117100!5m2!1sde!2sde"

export default () => {
    return (
        <PageTemplate>
            <title>Kontakt</title>
            <Container p="2">
                <Heading id="kontakt" >Kontakt</Heading>
                <Divider></Divider>
                <table>
                    <tr>
                        <td> <Text sx={{fontWeight: 'bold', p:1} }>Tel. \ Fax:</Text> </td> <td><Link href="tel:+493518108704">0351/8108704</Link></td>
                    </tr>
                    <tr>
                        <td> <Text sx={{fontWeight: 'bold', p:1} }>Mobil:</Text> </td> <td><Link  href="tel:+491792791915">0179/2791915</Link></td>
                    </tr>
                    <tr>
                        <td> <Text sx={{fontWeight: 'bold', p:1} }>E-Mail:</Text> </td> <td><Link  href="mailto:thomas.blume@dbs-dresden.de">thomas.blume@dbs-dresden.de</Link></td>
                    </tr>
                </table>
                <br/>
                <table>
                    <tr>
                        <td> <Text sx={{fontWeight: 'bold', p:1} }>Mo-Do</Text> </td> <td><Text>8:00 - 12:00 und 13:00 - 19:00*</Text></td>
                    </tr>
                    <tr>
                        <td><Text sx={{fontWeight: 'bold', p:1} }>Fr</Text> </td> <td> 8:00 - 15:00*</td>
                    </tr>
                </table>
                <text>*nach vorheriger telefonischer Vereinbarung</text>
                <br/>
                <br/>
            </Container>
            <Heading id="adresse" >Adresse:  <Link href="https://goo.gl/maps/wV37iT7K6jYzRx9DA">Bautzner Str. 75, 01099 Dresden </Link></Heading>
            <Divider></Divider>

            <Embed src={mapsLink} />

        </PageTemplate>
)}

